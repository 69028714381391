import './Work.css'

export const Work = () => {
    return (
        <section className="work" id="work">
            <div className="work-section left">
                <h5>Site Reliability Engineer - AUG 2024 - Present </h5>
                <h5>SyscoLABS</h5>
                <h6>55A Srimath Anagarika Dharmapala Mawatha, Colombo 00300</h6>
            </div>
            <div className="work-section middle">
                <h5>DevOps Engineer - FEB 2022 - JUL 2024 </h5>
                <h5>Well Tech Solutions</h5>
                <h6>32/2 School Ln Sri Jayewardenepura Kotte 11222</h6>
            </div>
            <div className="work-section right">
                <h5>Intern DevOps Engineer - JAN 2021 - JUL 2021 </h5>
                <h5>ILABS</h5>
                <h6>240, 36 Denzil Kobbekaduwa Mawatha Battaramulla 10120</h6>
            </div>
        </section>
    );
}


              {/* <div className="task"><h7><p>• Applying Terraform an AWS infrastructure as code tool</p>
            <p>• Containerizing the application: Docker, Kubernetes</p>
            <p>• Design and setup serverless systems on AWS</p>
            <p>• Experience on AWS services: Lambda, API Gateway,</p>
            <p>DynamoDB, RDS, Load Balancer, VPC, ECS, ECR, SES, SNS, S3,</p>
            <p>Amplify, Cognito, Autoscaling, CloudFront, CloudWatch,</p>
            <p>Lightsail, Rout 53</p>
            <p>• Cyber Security Penetration test on web applications and</p>
            <p>Security testing using Nessus, Burp Suite, Wire Shark.</p>
            <p>• Database structure and handling: PostgreSQL, MySQL,</p>
            <p>MongoDB</p>
            <p>• Implementing Continuous Integration (CI) and Continuous</p>
            <p>Delivery (CD) processes.: GitHub Actions, Gitlab Pipeline,</p>
            <p>Bitbucket Pipeline</p>
            <p>• Version controlling: Git</p>
            <p>• Laravel, Python, Node, react applications containerization and deploy</p>
            <p>• Issues and Problem solving</p>
            <p>• Monitoring the traffic, recourses spec using CloudWatch</p>
            <p>dashboard and Setup CloudWatch Alerts</p>
            <p>• Experience on Azure: AppService, Virtual Machine, SQL Servers</p>
            <p>• Experience on GCP: Compute Engine, Cloud RUN</p></h7></div> */}