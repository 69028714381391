import sliit from "../assets/img/SLIIT.png";
import uom from "../assets/img/University_of_Moratuwa_logo.png"

export const Education = () => {
  
    return (
        <section className="education" id ="education">
            {/* <h1>Education</h1> */}
            <div className="image-container">
                <img className="uom-logo" src={uom} alt="uom Img" />
            </div>
            <h5>MSc Computer Science Specializing in Cloud Computing</h5>
            <h5>University of Moratuwa</h5>
            <h5>2024 - Present</h5>
            <div className="image-container-v2">
                <img className="sliit-logo" src={sliit} alt="SLIIT Img" />
            </div>
            <h5>BSc (Hons) in Information Technology Specializing in Cyber Security </h5>
            <h5>Sri Lanka Institute of Information Technology</h5>
            <h5>2019 - 2023</h5>
        </section>
    )
  }